import React, { useRef, useCallback } from 'react'
import { ReactTags, Tag } from 'react-tag-autocomplete'
import classnames from 'classnames'

import styles from './TagPicker.module.css'

export enum TagColor {
  gray = 'gray',
  green = 'green',
  blue = 'blue',
  red = 'red',
  yellow = 'yellow',
  purple = 'purple'
}

export type Option = {
  value: number | string | symbol | null
  label: string
}

/*
 * This component supports `options` in either format:
 *
 * ['abc', '123', 'efg']   OR
 * [{ label: 'ABC', value: 'abc'}, { label: 'onetwothree', value: 123 }]
 */

type Props = {
  value?: (number | string | symbol | null)[]
  onChange?: (tags: Array<string | number | symbol | null | Option>) => void
  options?: Array<string | number | Option>
  isError?: boolean
  disabled?: boolean
  className?: string
  maxLength?: number
  allowNew?: boolean
  tagStyle?: 'round' | 'tinted' | 'solid'
  color?: TagColor
  noSuggestions?: boolean
}

const TagPickerField = ({
  value = [],
  onChange,
  options = [],
  disabled,
  maxLength,
  allowNew,
  noSuggestions = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isError,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  className,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tagStyle = 'tinted',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  color = TagColor.gray
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pickerRef = useRef<any>(null)

  const existingTags = options
    .filter((o: string | number | Option) =>
      value.includes(typeof o === 'string' || typeof o === 'number' ? o : o.value)
    )
    .map((o: string | number | Option) => ({
      value: typeof o === 'string' || typeof o === 'number' ? o : o.value,
      label: typeof o === 'string' || typeof o === 'number' ? o.toString() : o.label
    }))

  const mapped = existingTags.map(t => t.value)
  const newItems = allowNew
    ? value.filter(v => !mapped.includes(v)).map(v => ({ value: v, label: v?.toString() || '' }))
    : []
  const tags = existingTags.concat(newItems)

  const suggestions = options.length
    ? options.map((o: string | number | Option) => ({
        value: typeof o === 'string' || typeof o === 'number' ? o : o.value,
        label: typeof o === 'string' || typeof o === 'number' ? o.toString() : o.label,
        disabled: maxLength && value.length >= maxLength ? true : false
      }))
    : []

  const suggestionsTransform = useCallback(
    (query: string, suggestion: Tag[]) => {
      const filteredSuggestions = suggestion.filter(suggestedItem => {
        // TODO: make work with excluded countries
        if (suggestedItem?.label?.toLowerCase()?.indexOf?.(query.toLowerCase()) > -1) {
          return true
        }
        return false
      })
      if (value.length) {
        const tagLessSuggestions = filteredSuggestions.filter(suggestedItem => {
          if (value.includes(suggestedItem.value)) {
            return false
          }
          return true
        })

        return tagLessSuggestions
      }
      return filteredSuggestions
    },
    [value]
  )

  const onDelete = useCallback(
    (tagIndex: number) => {
      // NOTE:
      //
      // Current version of react-tag-autocomplete seems to visually auto-sort
      // the items as they are added in the list. But the actual value is not
      // sorted. So there is a mismatch between the visual order and the actual
      // order of the tags.
      //
      // To fix that, need to sort the tags based on the index of their
      // corresponding labels, before removing
      const filteredTags = [
        ...options.map(option => (typeof option == 'object' ? option.value : option)).filter(v => value.includes(v))
      ]
      filteredTags.splice(tagIndex, 1)
      onChange?.(filteredTags)
    },
    [onChange, value, options]
  )

  const onAdd = useCallback(
    (tag: Tag) => {
      if (maxLength && value.length >= maxLength - 1) {
        pickerRef?.current?.inputEventHandlers?.onBlur()
        return
      }
      if (tag.value || tag.label) {
        const newTags = [...value, tag.value || tag.label]
        onChange?.(newTags)
      }
    },
    [maxLength, onChange, value]
  )

  const onValidate = useCallback(() => {
    if (maxLength && value.length >= maxLength) {
      return false
    }
    return true
  }, [maxLength, value.length])

  return (
    <div className={classnames(styles.tagPickerContainer, { [styles.containerDisabled]: disabled })}>
      <ReactTags
        ref={pickerRef}
        selected={tags}
        suggestions={suggestions}
        renderInput={({ classNames, inputWidth, ...inputProps }) => {
          return (
            <input
              className={classNames.input}
              style={{ width: inputWidth }}
              disabled={Boolean(maxLength && value.length > maxLength)}
              {...inputProps}
            />
          )
        }}
        allowNew={allowNew}
        placeholderText=""
        noOptionsText={noSuggestions ? undefined : 'No results found'}
        suggestionsTransform={suggestionsTransform}
        onDelete={onDelete}
        onAdd={onAdd}
        onValidate={onValidate}
        classNames={{
          root: `${styles['react-tags']} ${isError && styles['error']} ${className}`,
          rootIsActive: styles['is-active'],
          rootIsDisabled: styles['is-disabled'],
          rootIsInvalid: styles['is-invalid'],
          label: styles['react-tags__label'],
          tagList: styles['react-tags__list'],
          tagListItem: styles['react-tags__list-item'],
          tag: `${styles['react-tags__tag']} ${styles[tagStyle]} ${styles[color]}`,
          tagName: styles['react-tags__tag-name'],
          comboBox: styles['react-tags__combobox'],
          input: styles['react-tags__combobox-input'],
          listBox: styles['react-tags__listbox'],
          option: styles['react-tags__listbox-option'],
          optionIsActive: styles['is-active'],
          highlight: styles['react-tags__listbox-option-highlight']
        }}
      />
    </div>
  )
}

export default TagPickerField
