import classNames from 'classnames'
import React from 'react'
import capitalize from 'lodash.capitalize'
import styles from './CardSelector.module.css'

type cardInfo = {
  id: string
  image: string
  title: string
  subtitle?: { id: string; name: string }[] | string
}
interface CardSelectorProps {
  title?: string
  cardsInfo: cardInfo[]
  name: string
  value?: string[]
  className?: string
  onClick?: (selectedItems: string[]) => void
  maxSelectedItems?: number
}

const cx = classNames.bind(styles)

const getSubtitle = (subtitleArray: { id: string; name: string }[]) => {
  const subtitle = subtitleArray.length
    ? subtitleArray.slice(0, 3).map((childCategory, index: number) => {
        return `${index === 0 ? capitalize(childCategory.name) : childCategory.name.toLowerCase()}${
          index === 2 || index === subtitleArray.length - 1 ? '' : ', '
        }`
      })
    : []
  return subtitleArray.length >= 3 ? [...subtitle, ' ', 'and more'] : subtitle
}

const CardSelector = ({
  name,
  title,
  value = [],
  className,
  cardsInfo,
  onClick,
  maxSelectedItems
}: CardSelectorProps) => {
  return (
    <div className={styles.selectorContainer}>
      {title && <h2 className={styles.selectorTitle}>{title}</h2>}
      <div className={cx(styles.cardsContainer, className)}>
        {cardsInfo.map((card, index) => {
          const disableCard =
            Boolean(maxSelectedItems) && value.length >= (maxSelectedItems ?? 0) && !value.includes(card.id as string)
          return (
            <button
              type="button"
              className={cx(styles.cardContainer, disableCard ? styles.disabledCard : '')}
              name={name}
              key={index}
              onClick={() => {
                if (value.includes(card.id as string)) {
                  value = value.filter(selectedOption => selectedOption != card.id)
                } else {
                  value = [...value, card.id]
                }
                if (onClick) onClick(value)
              }}
              disabled={disableCard}>
              <div
                className={cx(
                  styles.cardImageContainer,
                  value?.includes(card.id) ? styles.selectedCardImageContainer : ''
                )}
                style={{
                  backgroundImage: `url(${card.image})`
                }}
              />
              <h3 className={styles.cardTitle}>{card.title}</h3>
              <p className={styles.cardSubtitle}>
                {Array.isArray(card.subtitle) ? getSubtitle(card.subtitle) : card.subtitle}
              </p>
              {value?.includes(card.id) && <p className={styles.selectedBadge}>Selected</p>}
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default CardSelector
