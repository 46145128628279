import { useEffect, useState } from 'react'
import Select, {
  ContainerProps,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  GroupProps,
  IndicatorSeparatorProps,
  MenuListProps,
  MenuProps,
  MultiValue,
  OptionProps,
  PlaceholderProps,
  components
} from 'react-select'
import classNames from 'classnames/bind'

import Icon from 'components/Icon'
import CheckboxRadio from 'components/CheckboxRadio'

import { statesAndRegionsAUOptions, statesAndRegionsNZOptions } from 'lib/countries'

import styles from './RegionCheckboxDropdown.module.css'

type RegionCheckboxDropdownProps = {
  className?: string
  region: 'AU' | 'NZ'
  kind?: 'default' | 'light' | 'primary'
  checkboxKind?: 'default' | 'primary' | 'light'
  onChange?: (value: MultiValue<string>) => void
  value?: string[]
  placeholder?: string
  disabled?: boolean
  disabledOptions?: string[]
  hasError?: boolean
  isSubmitting?: boolean
}

const RegionCheckboxDropdown = ({
  className,
  region,
  kind = 'default',
  checkboxKind = 'default',
  onChange,
  value,
  placeholder = '',
  disabled,
  disabledOptions,
  hasError,
  isSubmitting
}: RegionCheckboxDropdownProps) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false)
  const cx = classNames.bind(styles)
  const DropdownIndicator = (props: DropdownIndicatorProps<string, true, GroupBase<string>>) => {
    const { menuIsOpen } = props.selectProps
    const kind = menuIsOpen ? 'chevron-up' : 'chevron-down'

    return (
      <components.DropdownIndicator {...props}>
        <Icon kind={kind} size={20} />
      </components.DropdownIndicator>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Option = (props: OptionProps<any>) => {
    const disableCheckbox = disabled || disabledOptions?.includes(props.data.value)

    return (
      <components.Option
        {...props}
        className={cx(styles.customOption, {
          [styles.isSubRegionIndent]: props.data.isSubRegion,
          [styles.disabled]: disableCheckbox
        })}>
        <CheckboxRadio
          className={styles.checkbox}
          type="checkbox"
          kind={checkboxKind}
          checked={props.isSelected}
          name={props.data.value}
          onChange={() => null}
          disabled={disableCheckbox}
        />
        <label>{props.label}</label>
      </components.Option>
    )
  }

  const Group = (props: GroupProps<string, true, GroupBase<string>>) => {
    return (
      <div>
        <components.Group {...props} className={styles.customGroup}>
          {props.children}
        </components.Group>
      </div>
    )
  }

  // Obtain available regions for AU and NZ from lib/countries
  const availableAURegions = statesAndRegionsAUOptions.flatMap(region => region.options)
  const availableNZRegions = statesAndRegionsNZOptions
    .flatMap(region => region.options)
    .map(({ label, value }) => {
      return { label: label, value: value }
    })

  const options = (region === 'AU' ? statesAndRegionsAUOptions : statesAndRegionsNZOptions) as unknown as readonly (
    | string
    | GroupBase<string>
  )[]

  const getPlaceholderValue = (region: string, placeholder: string) => {
    if (value?.length === 0) {
      return placeholder
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (value?.map((v: any) => v.value).includes(region)) {
      return region === 'AU'
        ? availableAURegions.filter(reg => reg.value === region).map(v => v.label)
        : availableNZRegions.filter(reg => reg.value === region).map(v => v.label)
    } else if (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value?.map((v: any) => v.value).includes('NZ-NORTH') &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      !value?.map((v: any) => v.value).includes('NZ-SOUTH')
    ) {
      return 'North Island'
    } else if (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value?.map((v: any) => v.value).includes('NZ-SOUTH') &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      !value?.map((v: any) => v.value).includes('NZ-NORTH')
    ) {
      return 'South Island'
    } else {
      return 'Selected Regions'
    }
  }

  const handleOnChange = (selectedItems: MultiValue<string>) => {
    onChange && onChange(selectedItems)
  }

  useEffect(() => {
    if (isSubmitting) {
      setIsFormSubmitting(true)
    }
  }, [isSubmitting])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customStyles: any = {
    default: {
      control: (provided: ControlProps) => ({
        ...provided,
        backgroundColor: 'var(--colorGray2)',
        color: 'var(--colorBlack2)',
        border: 0,
        boxShadow: 'none',
        minHeight: '32px',
        borderRadius: '2px'
      }),
      container: (provided: ContainerProps) => ({
        ...provided,
        border: hasError && isFormSubmitting ? '1px solid var(--colorRed) !important' : null,
        boxShadow: hasError && isFormSubmitting ? '0 0 0 4px var(--colorRedFade)' : null
      }),
      placeholder: (provided: PlaceholderProps) => ({
        ...provided,
        color: 'var(--colorBlack2)',
        fontWeight: '500'
      }),
      dropdownIndicator: (provided: DropdownIndicatorProps) => ({
        ...provided,
        color: 'var(--colorBlack2)',
        padding: '6px'
      }),
      menu: (provided: MenuProps) => ({
        ...provided,
        minWidth: '200px'
      }),
      menuList: (provided: MenuListProps) => ({
        ...provided,
        padding: 0,
        '::-webkit-scrollbar': {
          width: '1px'
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: 'transparent'
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: 'transparent'
        }
      }),
      indicatorSeparator: (provided: IndicatorSeparatorProps) => ({
        ...provided,
        display: 'none'
      }),
      option: (provided: OptionProps) => ({
        ...provided,
        color: 'var(--colorBlack2)',
        backgroundColor: 'none',
        ':hover': {
          backgroundColor: 'var(--colorGray1)'
        }
      })
    },
    light: {
      control: (provided: ControlProps) => ({
        ...provided,
        backgroundColor: 'white',
        color: 'var(--colorBlack2)',
        borderColor: 'var(--colorGray3)',
        boxShadow: 'none',
        minHeight: '32px',
        borderRadius: '2px',
        '&:hover': {
          borderColor: 'var(--colorGray3)'
        }
      }),
      container: (provided: ContainerProps) => ({
        ...provided,
        border: hasError && isFormSubmitting ? '1px solid var(--colorRed) !important' : null,
        boxShadow: hasError && isFormSubmitting ? '0 0 0 4px var(--colorRedFade)' : null
      }),
      placeholder: (provided: PlaceholderProps) => ({
        ...provided,
        color: 'var(--colorBlack2)',
        fontWeight: '500'
      }),
      dropdownIndicator: (provided: DropdownIndicatorProps) => ({
        ...provided,
        color: 'var(--colorBlack2)',
        padding: '6px'
      }),
      menu: (provided: MenuProps) => ({
        ...provided,
        minWidth: '200px'
      }),
      menuList: (provided: MenuListProps) => ({
        ...provided,
        padding: 0,
        '::-webkit-scrollbar': {
          width: '1px'
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: 'transparent'
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: 'transparent'
        }
      }),
      indicatorSeparator: (provided: IndicatorSeparatorProps) => ({
        ...provided,
        display: 'none'
      }),
      option: (provided: OptionProps) => ({
        ...provided,
        color: 'var(--colorBlack2)',
        backgroundColor: 'none',
        ':hover': {
          backgroundColor: 'var(--colorGray1)'
        }
      })
    }
  }

  return (
    <Select
      isMulti
      className={cx(styles.container, className, { [styles.disabled]: disabled })}
      styles={customStyles[kind || 'default']}
      components={{ Option, Group, DropdownIndicator }}
      placeholder={getPlaceholderValue(region, placeholder)}
      controlShouldRenderValue={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isDisabled={disabled}
      isSearchable={false}
      isClearable={false}
      value={value}
      options={options}
      onChange={handleOnChange}
      onInputChange={() => setIsFormSubmitting(true)}
    />
  )
}

export default RegionCheckboxDropdown
