import React from 'react'
import classNames from 'classnames/bind'
import Badge from 'components/Badge'
import styles from './FormControls.module.css'

const cx = classNames.bind(styles)

const Datum = ({
  badge,
  title,
  value,
  isHeading
}: {
  badge?: string
  title?: string
  value?: string
  isHeading?: boolean
}) => (
  <div className={styles.datumContainer}>
    {title && <div className={cx('typography-nano', styles.datumTitle)}>{title}</div>}
    <div className={cx(styles.datumValue, { isHeading })}>
      {value}
      {badge && <Badge className={styles.datumBadge}>{badge}</Badge>}
    </div>
  </div>
)

export default Datum
