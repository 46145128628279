import React, { useEffect } from 'react'
import classNames from 'classnames'

import { useToast } from 'components/Toast'
import Badge, { BadgeColor } from 'components/Badge'
import { AccountVerificationResultStatusEnum } from '../../../../../__generated__/globalTypes'
import { CheckAccountDetails_accountVerification } from '../SearchForBusinessNumberForm/graphql/__generated__/CheckAccountDetails'

import styles from './BusinessNumberResultsCard.module.css'
import formatTaxNumber from 'lib/format-tax-number'

interface BusinessNumberResultsCardProps {
  businessCardsInfo: CheckAccountDetails_accountVerification[]
  name: string
  value?: string
  className?: string
  onClick?: (selectedItem: string) => void
}

const cx = classNames.bind(styles)

const BusinessNumberResultsCard = ({
  name,
  value = '',
  className,
  businessCardsInfo,
  onClick
}: BusinessNumberResultsCardProps) => {
  const [showToast] = useToast()

  useEffect(() => {
    if (businessCardsInfo.length === 1 && businessCardsInfo[0].status === AccountVerificationResultStatusEnum.ACTIVE) {
      if (!businessCardsInfo[0].businessName) {
        showToast({ kind: 'error', message: 'Private business names cannot be selected' })
        return
      }

      if (onClick) onClick(businessCardsInfo[0].taxNumber ?? '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={cx(styles.cardsContainer, className)}>
      {businessCardsInfo.map((card, index) => {
        return (
          <button
            type="button"
            className={cx(
              styles.cardContainer,
              value === card.taxNumber ? styles.selectedCardContainer : '',
              card.status === AccountVerificationResultStatusEnum.CANCELLED ? styles.disabledCardContainer : ''
            )}
            name={name}
            key={index}
            onClick={() => {
              if (!card.businessName) {
                showToast({ kind: 'error', message: 'Private business names cannot be selected' })
                return
              }

              value = card.taxNumber === value ? '' : card.taxNumber ?? ''
              if (onClick) onClick(value)
            }}>
            <div className={styles.businessIdSection}>
              <p className={styles.boldText}>
                {card.taxName +
                  ' ' +
                  formatTaxNumber({
                    taxNumber: card.taxNumber ?? '',
                    country:
                      card.taxName === 'ABN'
                        ? 'AU'
                        : card.taxName === 'NZBN'
                        ? 'NZ'
                        : card.taxName === 'VAT'
                        ? 'GB'
                        : card.taxName === 'EIN'
                        ? 'US'
                        : ''
                  })}
              </p>
              <Badge
                color={card.status === AccountVerificationResultStatusEnum.ACTIVE ? BadgeColor.green : BadgeColor.red}
                style="solid"
                className={styles.businessStatus}>
                {card.status?.toLocaleLowerCase()}
              </Badge>
            </div>

            <div>
              <p className={styles.fieldTitle}>Name</p>
              {!card.businessName ? (
                <p className={cx(styles.boldText, styles.private)}>Business name kept private</p>
              ) : (
                <p className={styles.boldText}>{card.businessName}</p>
              )}
            </div>

            {(card.state || card.postcode) && (
              <div>
                <p className={styles.fieldTitle}>Main business location</p>
                <p className={styles.location}>{(card.state || '') + ' ' + (card.postcode || '')}</p>
              </div>
            )}
          </button>
        )
      })}
    </div>
  )
}

export default BusinessNumberResultsCard
