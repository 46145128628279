import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import AsyncSelect from 'react-select/async'
import {
  components,
  OptionProps,
  MenuProps,
  SingleValue,
  IndicatorSeparatorProps,
  DropdownIndicatorProps,
  ContainerProps,
  ControlProps
} from 'react-select'

import Button from 'components/Button'
import Badge, { BadgeColor } from 'components/Badge'

import { fullCountryName } from 'lib/countries'
import AddressPickerModal from 'components/AddressPickerModal'
import { AddressableTypeEnum, AddressAttributesArguments } from '../../../__generated__/globalTypes'
import { UseAddressPickerOperationEnum } from 'components/AddressPickerForm/useAddressPickerForm'

import GET_IDS_FOR_ADDRESSPICKER from './graphql/GetIdsForAddressPicker.graphql'
import { GetIdsForAddressPicker } from './graphql/__generated__/GetIdsForAddressPicker'

import styles from './AddressPicker.module.css'

// Helper Functions
const createAddressLabel = (addressData: AddressAttributesArguments) => {
  const { street1, street2, city, state, country } = addressData
  return `${street1}, ${street2 ? `${street2}, ` : ''}${city}, ${state}, ${fullCountryName(country as string)}`
}
const newOptions = (options: Array<AddressAttributesArguments>) => {
  const addressOptions = options.map(addressData => {
    return {
      label: addressData && createAddressLabel(addressData),
      value: addressData
    }
  })
  return addressOptions
}

export type AddressPickersValueProps = {
  label?: string
  value: AddressAttributesArguments | null
}

type AddressPickerProps = {
  className?: string
  placeholder?: string
  options: Array<AddressAttributesArguments>
  disabled?: boolean
  autoFocus?: boolean
  kind?: 'default' | 'primary'
  onChange?: ((e: AddressPickersValueProps) => void | undefined) | undefined
  value?: AddressPickersValueProps
  hideEditButton?: boolean
  hideAddButton?: boolean
}

const AddressPicker = ({
  className,
  placeholder = 'Enter an address',
  disabled,
  autoFocus,
  kind = 'default',
  options,
  onChange,
  value,
  hideEditButton = false,
  hideAddButton = false
}: AddressPickerProps) => {
  const [operation, setOperation] = useState<UseAddressPickerOperationEnum>(UseAddressPickerOperationEnum.EDIT)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [currentValue, setCurrentValue] = useState<AddressPickersValueProps | undefined>({
    label: value && createAddressLabel(value as unknown as AddressAttributesArguments),
    value: value as unknown as AddressAttributesArguments
  })
  // const [currentValue, setCurrentValue] = useState<AddressPickersValueProps | undefined>()
  const defaultOptions = newOptions(options)

  const { data } = useQuery<GetIdsForAddressPicker>(GET_IDS_FOR_ADDRESSPICKER)

  const userId = data?.currentUser?.id ?? ''
  const accountType = data?.currentAccount?.type.toUpperCase() ?? ''
  const addressableId = data?.currentAccount?.accountable?.id ?? ''
  const addressableType = AddressableTypeEnum[accountType as AddressableTypeEnum]

  // Custom `react-select` components
  const CustomMenu = (props: MenuProps<{ label: string; value: AddressAttributesArguments }, false>) => {
    return (
      <components.Menu {...props}>
        <>
          <div className={styles.addressMenuItemsContiner}>{props.children}</div>
          {!hideAddButton && (
            <div className={classNames(styles.addAddressBtnContainer)}>
              <Button icon="plus" onClick={handleClickAddAddress}>
                Add address
              </Button>
            </div>
          )}
        </>
      </components.Menu>
    )
  }
  const CustomOption = (props: OptionProps<{ label: string; value: AddressAttributesArguments }, false>) => {
    const { locationType, nickname, street1, street2, city, state, country, postcode } = props.data
      .value as AddressAttributesArguments

    return (
      <components.Option {...props}>
        <div className={styles.optionItemContainer}>
          <div className={styles.optionItemUpperHalf}>
            {locationType && (
              <Badge className={styles.locTypeBadge} color={BadgeColor.gray} style={'tinted'} hasMargin>
                {locationType.replaceAll('_', ' ')}
              </Badge>
            )}
            <p className={styles.nickname}>{nickname}</p>
          </div>
          <div className={styles.optionItemLowerHalf}>
            <p>
              {street1}, {street2 && `${street2}, `}
              {city},
            </p>
            <p>
              {state}, {postcode}, {fullCountryName(country as string)}
            </p>
          </div>
        </div>
      </components.Option>
    )
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customStyles: any = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (provided: ControlProps) => ({
      ...provided,
      backgroundColor: disabled ? 'var(--colorGray1)' : 'var(--colorWhite)',
      border: 0,
      boxShadow: 'none',
      borderRadius: 2,
      minHeight: '30px'
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    container: (provided: ContainerProps, { isFocused }: any) => ({
      ...provided,
      border: !isFocused
        ? '1px solid var(--colorGray3)'
        : kind === 'primary'
        ? '1px solid var(--colorBlue)'
        : '1px solid var(--colorBlack)',
      boxShadow: !isFocused
        ? ''
        : kind === 'primary'
        ? '0 0 0 4px var(--colorBlueFade)'
        : '0 0 0 4px var(--colorGray2)',
      outline: isFocused && '0 none',
      borderRadius: 2
    }),
    dropdownIndicator: (provided: DropdownIndicatorProps) => ({
      ...provided,
      padding: '0 6px'
    }),
    indicatorSeparator: (provided: IndicatorSeparatorProps) => ({
      ...provided,
      display: 'none'
    })
  }

  const handleClickAddAddress = () => {
    setOperation(UseAddressPickerOperationEnum.ADD)
    setIsOpen(true)
  }

  const handleClickEditAddress = () => {
    setOperation(UseAddressPickerOperationEnum.EDIT)
    setIsOpen(true)
  }

  useEffect(() => {
    // Creates an address `label` for Formik's `label` field
    if (value && !value?.label) {
      setCurrentValue({
        label: createAddressLabel(value as unknown as AddressAttributesArguments),
        value: value as unknown as AddressAttributesArguments
      })
    } else {
      setCurrentValue(value)
    }
  }, [value])

  return (
    <>
      <div className={classNames(className, styles.container, { [styles.disabled]: disabled })}>
        <AsyncSelect
          className={styles.asyncSelect}
          styles={customStyles}
          components={{
            Menu: CustomMenu,
            Option: CustomOption
          }}
          placeholder={placeholder}
          defaultOptions={defaultOptions}
          isDisabled={disabled}
          autoFocus={autoFocus}
          onChange={(selectedValue: SingleValue<AddressPickersValueProps>) => {
            if (selectedValue === null) {
              onChange &&
                onChange({
                  value: null,
                  label: ''
                })
            } else {
              setCurrentValue(selectedValue)
              onChange && onChange(selectedValue)
            }
          }}
          value={defaultOptions.filter(option => option.value.id === currentValue?.value?.id)}
          isSearchable={false}
          blurInputOnSelect
        />

        {!hideEditButton && (
          <Button
            className={classNames(styles.editButton, { [styles.disabled]: disabled })}
            kind="dark"
            size="default"
            disabled={disabled || !value}
            onClick={handleClickEditAddress}>
            Edit
          </Button>
        )}
      </div>

      <AddressPickerModal
        operation={operation}
        userId={userId}
        addressId={currentValue?.value?.id as string}
        addressableId={addressableId}
        addressableType={addressableType}
        data={operation === UseAddressPickerOperationEnum.ADD || !currentValue ? undefined : currentValue.value}
        isOpen={isOpen}
        onExit={() => setIsOpen(false)}
      />
    </>
  )
}

export default AddressPicker
