import React, { MouseEventHandler } from 'react'
import classnames from 'classnames'
import styles from './CheckboxList.module.css'
import CheckboxRadio from 'components/CheckboxRadio'
import Switch from 'components/Switch'

const cx = classnames.bind(styles)

interface CheckboxListProps {
  name: string
  mode?: 'checkbox' | 'switch'
  options: {
    label: string
    id: string
    disabled?: boolean
  }[]
  value?: string[]
  disabled?: boolean
  className?: string
  numColumns?: number
  onChange?: (selectedItems: string[]) => void
  onClick?: MouseEventHandler<HTMLInputElement>
}

const CheckboxList = ({
  name,
  mode = 'checkbox',
  options,
  onChange,
  disabled,
  className,
  value = [],
  numColumns = 3,
  onClick
}: CheckboxListProps) => {
  const handleChange = (id: string) => {
    const newValue = value.includes(id) ? value.filter(selectedOption => selectedOption !== id) : [...value, id]

    if (onChange) onChange(newValue)
  }

  return (
    <div
      className={cx(styles.checkboxListContainer, className)}
      style={{ gridTemplateColumns: `repeat(${numColumns}, ${100 / numColumns}%)` }}>
      {options.map(option => (
        <div
          key={option.id}
          className={cx(styles.clickable, {
            [styles.checkbox]: mode === 'checkbox'
          })}>
          {mode === 'checkbox' ? (
            <CheckboxRadio
              type="checkbox"
              name={name}
              label={option.label}
              onChange={() => handleChange(option.id)}
              onClick={onClick}
              checked={value.includes(option.id)}
              disabled={disabled || option.disabled}
            />
          ) : (
            <Switch
              className={styles.testSwitch}
              name={option.id}
              label={option.label}
              onChange={() => handleChange(option.id)}
              checked={value.includes(option.id)}
              disabled={disabled || option.disabled}
            />
          )}
        </div>
      ))}
    </div>
  )
}

export default CheckboxList
