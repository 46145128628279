import { Formik, FormikValues, FormikConfig, FormikProps } from 'formik'

import { useToast } from 'components/Toast'

/*
 * Wrap <Formik, so we can add common behaviours here
 */
export function FormikWrapper<Values extends FormikValues = FormikValues, ExtraProps = Record<string, unknown>>({
  children,
  ...otherProps
}: FormikConfig<Values> & ExtraProps) {
  const [showToast] = useToast()

  const childrenWrapper = (props: FormikProps<Values>) => {
    const submitForm = async () => {
      // Bit of a hack, only way to raise a toast that there are form errors
      const errors = await props.validateForm()
      if (Object.entries(errors || {}).length > 0) {
        console.error('Form errors', errors, props.values)
        showToast({ kind: 'error', message: 'Please check the form for errors' })
      }

      // Submit the form anyway, or errors will not present on the form
      await props.submitForm()
    }

    if (typeof children === 'function') return children({ ...props, submitForm })
    else return <>{children}</>
  }

  const props = { children: childrenWrapper, ...otherProps } as FormikConfig<Values> & ExtraProps

  return (
    <>
      <Formik<Values, ExtraProps> {...props} />
    </>
  )
}

export default FormikWrapper
