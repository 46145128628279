type UserErrorFromGraphQL = {
  /**
   * A code representing the error
   */
  code: string | null
  /**
   * A description of the error
   */
  message: string | null
  /**
   * Which input value this error came from
   */
  path: string[] | null
}

/*
 * This function takes a graphql user error, and transforms it to a value that
 * Formik can understand
 *
 * @input [{message: 'is already taken', path: ['varietyAttributes', '0', 'sku'] }]
 * @output [{message: 'is already taken', path: 'varietyAttributes[0].sku' }]
 */
const joinErrorPaths = (errors?: UserErrorFromGraphQL[]) => {
  return errors?.map(e => {
    const { code, message } = e
    const path = e.path?.reduce((prev, curr) => {
      if (curr.match(/^[0-9]+$/)) {
        curr = `[${curr}]`
      } else if (prev) {
        curr = '.' + curr
      }

      return prev + curr
    }, '')

    return {
      code,
      message,
      path
    }
  })
}

export default joinErrorPaths
