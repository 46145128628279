import { useState } from 'react'
import classnames from 'classnames'

import Icon from 'components/Icon'
import styles from './CreditCardInput.module.css'

export type CreditCardValueProps = {
  cardNumber: string
  expiry: string
  cvc: number | undefined
}

type CreditCardInputProps = {
  className?: string
  name?: string
  kind?: 'primary' | 'default'
  value?: CreditCardValueProps
  disabled?: boolean
  onChange?: (val: CreditCardValueProps) => void
  hasError?: boolean
}

const CreditCardInput = ({
  className,
  kind = 'default',
  value,
  disabled = false,
  onChange,
  hasError
}: CreditCardInputProps) => {
  const [focus, setFocus] = useState<boolean>(false)
  const [ccValue, setCCValue] = useState({
    cardNumber: value?.cardNumber ?? '',
    expiry: value?.expiry ?? '',
    cvc: value?.cvc ?? undefined
  })

  const addSpaceToValue = (e: { currentTarget: { value: string } }) => {
    const number = e.currentTarget.value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim()
    setCCValue({ ...ccValue, cardNumber: number })
    onChange?.({ ...ccValue, cardNumber: number })
  }

  const addSlashToValue = (e: { currentTarget: { value: string } }) => {
    const number = e.currentTarget.value
    setCCValue({ ...ccValue, expiry: number })
    onChange?.({ ...ccValue, expiry: number })
  }

  const handleChangeCvc = (e: { currentTarget: { value: string } }) => {
    const number = parseInt(e.currentTarget.value)
    setCCValue({ ...ccValue, cvc: number })
    onChange?.({ ...ccValue, cvc: number })
  }

  console.log('hasError: ', hasError)

  return (
    <div
      className={classnames(
        styles.container,
        {
          [styles[kind]]: true,
          [styles.isActive]: focus,
          [styles.isError]: hasError,
          [styles.disabled]: disabled
        },
        className
      )}>
      <div className={styles.left}>
        <Icon kind="credit-card" color="var(--colorGray3)" size={24} />
        <input
          className={classnames(styles.cardNumber, { [styles.disabled]: disabled })}
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck={false}
          name="cardNumber"
          placeholder="Card Number"
          onChange={addSpaceToValue}
          value={ccValue.cardNumber}
          maxLength={19}
          disabled={disabled}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </div>
      <div className={styles.right}>
        <input
          className={classnames(styles.expiry, { [styles.disabled]: disabled })}
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck={false}
          name="expiry"
          placeholder="MM / YY"
          onChange={addSlashToValue}
          value={ccValue.expiry}
          maxLength={5}
          disabled={disabled}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        <input
          className={classnames(styles.cvc, { [styles.disabled]: disabled })}
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck={false}
          name="cvc"
          placeholder="CVC"
          onChange={handleChangeCvc}
          value={ccValue.cvc ?? ''}
          maxLength={3}
          disabled={disabled}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </div>
    </div>
  )
}

export default CreditCardInput
