import { useCallback, useState, useEffect, ChangeEvent } from 'react'
import { useDebouncedCallback } from 'use-debounce'

type InputProps = React.ComponentProps<'input'>

const INPUT_DELAY = 200

const DebouncedInput = (props: InputProps) => {
  const [innerValue, setInnerValue] = useState('')

  useEffect(() => {
    if (props.value != null) {
      setInnerValue(props.value as string)
    } else {
      setInnerValue('')
    }
  }, [props.value])

  const debouncedHandleOnChange = useDebouncedCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event)
    }
  }, INPUT_DELAY)

  const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.persist()

    const newValue = event.currentTarget.value
    setInnerValue(newValue)
    debouncedHandleOnChange(event)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <input {...props} value={innerValue} onChange={handleOnChange} />
}

export default DebouncedInput
