import useAddressPickerForm, { UseAddressPickerFormProps } from './useAddressPickerForm'
import FormikWrapper from 'components/FormikWrapper'

export type AddressPickerFormProps = {
  children?: React.ReactNode
} & UseAddressPickerFormProps

const AddressPickerForm = ({
  userId,
  addressId,
  addressableId,
  addressableType,
  operation,
  data,
  onCompleted,
  children
}: AddressPickerFormProps) => {
  const { initialValues, validationSchema, onSubmit } = useAddressPickerForm({
    userId,
    addressId,
    addressableId,
    addressableType,
    operation,
    data,
    onCompleted
  })

  return (
    <FormikWrapper
      /* NOTE:
       * Changing operation requires changing initialValues back and forth.
       * This results to a bug in Formik where on the next render it displays the old values
       * instead of the new ones. This issue is solved by just adding a key to Formik :|
       * Link to fix (Item 3): https://github.com/jaredpalmer/formik/issues/811#issuecomment-1059814695
       */
      key={operation}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {children}
    </FormikWrapper>
  )
}

export default AddressPickerForm
