import classnames from 'classnames'

import ModalContainer, { ModalContainerProps } from 'components/ModalContainer/ModalContainer'
import Icon from 'components/Icon'
import styles from './ModalDialog.module.css'
import { ReactNode } from 'react'

export type ModalDialogProps = ModalContainerProps & {
  descriptor?: string | ReactNode
  contentClassName?: string
  bodyClassName?: string
  buttonsLeft?: React.ReactNode
  buttonsRight?: React.ReactNode
  showHeader?: boolean
}

const ModalDialog = ({
  title,
  descriptor,
  className,
  contentClassName,
  bodyClassName,
  children,
  onExit,
  buttonsLeft,
  buttonsRight,
  closeable = true,
  showHeader = true,
  ...rest
}: ModalDialogProps) => {
  return (
    <ModalContainer
      onExit={onExit}
      title={title}
      closeable={closeable}
      className={classnames(styles.modalOuterContainer, className)}
      {...rest}>
      <div className={classnames(styles.modalContainer, contentClassName)}>
        {showHeader && (title || descriptor) && (
          <div className={styles.modalHeader}>
            <div className={styles.headerLeft}>
              {title && <div className={styles.modalTitle}>{title}</div>}
              {descriptor && <div className={styles.modalDescriptor}>{descriptor}</div>}
            </div>
            {closeable && (
              <nav className={styles.modalNav}>
                <a className={styles.modalClose} onClick={() => onExit()}>
                  <Icon kind="x" size={16} color="black" />
                </a>
              </nav>
            )}
          </div>
        )}

        <div className={classnames(styles.modalBody, bodyClassName)}>{children && children}</div>

        {(buttonsLeft || buttonsRight) && (
          <div className={styles.modalFooter}>
            <div className={styles.footerLeft}> {buttonsLeft && buttonsLeft}</div>

            <div className={styles.footerRight}>{buttonsRight && buttonsRight}</div>
          </div>
        )}
      </div>
    </ModalContainer>
  )
}

export default ModalDialog
