import React, { ChangeEvent, useEffect, useState, useCallback } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import classNames from 'classnames'

import { FieldsetRow, FormField } from 'components/FormControls'
import { ButtonProps } from 'components/Button/Button'
import Button from 'components/Button'

import { shortenCountryName, countryOptions, priorityCountryOptions } from 'lib/countries'
import { AddressAttributesArguments } from './../../../__generated__/globalTypes'

import styles from './AddressAutocomplete.module.css'

export type AddressValueProps = Pick<
  AddressAttributesArguments,
  'id' | 'street1' | 'street2' | 'city' | 'state' | 'country' | 'postcode'
>

export type LocationValueProps = Pick<AddressAttributesArguments, 'id' | 'city' | 'state' | 'country' | 'postcode'>

type GoogleAutocompleteProps = {
  value: {
    description: string
    matched_substrings: [
      {
        length: number
        offset: number
      }
    ]
    place_id: string
    reference: string
    structured_formatting: {
      main_text: string
      main_text_matched_substrings: [
        {
          length: number
          offset: number
        }
      ]
      secondary_text: string
    }
    terms: [
      {
        offset: number
        value: string
      }
    ]
    types: [string]
  }
} & AddressValueProps

type AddressAutocompleteProps = {
  className?: string
  name?: string
  placeOnly?: boolean
  hasManualMode?: boolean
  restrictCountries?: string | string[]
  placeholder?: string
  disabled?: boolean
  value?: AddressValueProps | LocationValueProps
  kind?: 'default' | 'primary'
  onChange?: (item: AddressValueProps | LocationValueProps | null) => void
  hasError?: boolean
  isTouched?: boolean
} & ButtonProps

const AddressAutocomplete = ({
  className,
  name = 'name',
  placeOnly = false,
  hasManualMode = true,
  restrictCountries,
  placeholder = 'Enter an address',
  disabled,
  value,
  kind = 'primary',
  onChange,
  isSubmitting,
  hasError,
  isTouched
}: AddressAutocompleteProps) => {
  const [addressId, setAddressId] = useState<string | undefined>(value?.id ?? undefined)
  const [isManual, setIsManual] = useState<boolean>(false)

  // Used to display fieldset label-value
  const [addressValue, setAddressValue] = useState<{
    label: string
    value: AddressValueProps | LocationValueProps
  }>()
  // Google address, contains place_id and other google address fields
  const [googleAddress, setGoogleAddress] = useState<GoogleAutocompleteProps>()

  const resetAddresses = () => {
    setAddressValue(undefined)
    setGoogleAddress(undefined)
  }

  // Helper functions
  const generateLabelForGoogleAddress = useCallback(
    (value: AddressValueProps | LocationValueProps | undefined, placeOnly: boolean) => {
      let addressLabel

      if (placeOnly) {
        const v = value as LocationValueProps

        addressLabel = `${v?.city ? `${v.city}, ` : ''}${v?.state ? `${v.state}, ` : ''}${
          v?.country ? shortenCountryName(countryOptions.find(c => c?.value === v?.country)?.label) : ''
        }`
      } else {
        const v = value as AddressValueProps

        addressLabel = `${v?.street1 ? `${v.street1}, ` : ''}${v?.street2 ? `${v?.street2}, ` : ''}${
          v?.city ? `${v.city}, ` : ''
        }${v?.state ? `${v.state}, ` : ''}${
          v?.country ? shortenCountryName(countryOptions.find(c => c?.value === v?.country)?.label) : ''
        }`
      }

      return addressLabel
    },
    []
  )

  const parseGoogleAddress = useCallback(
    async (address: GoogleAutocompleteProps) => {
      /*
       * 'lib/countries' package follows ISO-3166 country code format
       * https://www.iban.com/country-codes
       * https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
       *
       * So some Google country results (USA, UK) need to be rephrased in order to
       * display their country value.
       */
      let country = address?.value?.terms?.at(-1)?.value
      if (country === 'USA' || country === 'United States') {
        country = 'United States of America'
      }
      if (country === 'UK' || country === 'GB') {
        country = 'United Kingdom of Great Britain and Northern Ireland'
      }

      const street = placeOnly
        ? {}
        : {
            street1: address.value.structured_formatting.main_text,
            street2: ''
          }

      const addressParts = {
        id: value?.id,
        ...street,
        city: address?.value?.terms?.slice(-3)[0]?.value,
        state: address?.value?.terms?.slice(-2)[0]?.value,
        postcode: (await getPostCode(address?.value?.place_id)) as string,
        country: countryOptions.find(c => c?.label === country)?.value
      }

      // This is mainly for the UK, where the street name is usually in the 3rd last term
      if (addressParts.street1?.endsWith(addressParts.city)) {
        addressParts.city = addressParts.state
      }

      return addressParts
    },
    [value?.id, placeOnly]
  )

  const getPostCode = async (placeId: string) => {
    const getPostalCode = new Promise((resolve, reject) => {
      try {
        new window.google.maps.places.PlacesService(document.createElement('div')).getDetails(
          {
            placeId,
            fields: ['address_components']
          },
          details => {
            let postcode = undefined
            details?.address_components?.forEach(entry => {
              if (entry.types?.[0] === 'postal_code') {
                postcode = entry.long_name
              }
            })
            return resolve(postcode)
          }
        )
      } catch (e) {
        console.log(e)
        reject(e)
      }
    })
    return await getPostalCode
  }

  const handleChangeMode = () => {
    setIsManual(prevState => !prevState)
  }

  useEffect(() => {
    if (value) {
      setAddressValue({
        label: generateLabelForGoogleAddress(value, placeOnly),
        value: value
      })
      value.id && setAddressId(value.id)
    }
  }, [value, placeOnly, generateLabelForGoogleAddress])

  const manualChange = (field: string, v: string) => {
    onChange?.(Object.assign({}, value, { [field]: v }))
  }

  return (
    <div className={styles.container}>
      {isManual ? (
        <div className={styles.inputContainer}>
          {!placeOnly && (
            <FormField
              type="text"
              name={`${name}.street1`}
              kind="primary"
              title="Street 1*"
              useDebounced={true}
              onChange={(event: ChangeEvent<HTMLInputElement>) => manualChange('street1', event.target?.value)}
            />
          )}

          {!placeOnly && (
            <FormField
              type="text"
              name={`${name}.street2`}
              kind="primary"
              title="Street 2"
              useDebounced={true}
              onChange={(event: ChangeEvent<HTMLInputElement>) => manualChange('street2', event.target?.value)}
            />
          )}

          <FormField
            type="text"
            name={`${name}.city`}
            kind="primary"
            title="City*"
            useDebounced={true}
            onChange={(event: ChangeEvent<HTMLInputElement>) => manualChange('city', event.target?.value)}
          />

          <FieldsetRow>
            <FormField
              type="text"
              name={`${name}.state`}
              kind="primary"
              title="State*"
              useDebounced={true}
              onChange={(event: ChangeEvent<HTMLInputElement>) => manualChange('state', event.target?.value)}
            />

            <FormField
              type="text"
              name={`${name}.postcode`}
              kind="primary"
              title={`Postcode${placeOnly ? '' : '*'}`}
              useDebounced={true}
              onChange={(event: ChangeEvent<HTMLInputElement>) => manualChange('postcode', event.target?.value)}
            />
          </FieldsetRow>

          <FormField
            type="select"
            name={`${name}.country`}
            kind="primary"
            title="Country*"
            useDebounced={true}
            // Show countries in priorityCountryOptions present in restrictCountries
            optionsPriority={
              restrictCountries
                ? priorityCountryOptions().filter(c => restrictCountries.includes(c.value))
                : priorityCountryOptions()
            }
            // Show countries in countryOptions present in restrictCountries and not in priorityCountryOptions
            options={
              restrictCountries
                ? countryOptions.filter(
                    c =>
                      restrictCountries.includes(c.value) &&
                      !priorityCountryOptions().filter(c => restrictCountries.includes(c.value))
                  )
                : countryOptions
            }
            isReadOnly={value?.country}
            onChange={(event: { label: string; value: string }) => manualChange('country', event.value)}
          />
        </div>
      ) : (
        <GooglePlacesAutocomplete
          apiKey={process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY}
          debounce={250}
          selectProps={{
            isClearable: true,
            className: classNames(className, styles.inputContainer),
            styles: {
              control: provided => ({
                ...provided,
                backgroundColor: disabled ? 'var(--colorGray1)' : 'var(--colorWhite)',
                border: 0,
                boxShadow: 'none',
                borderRadius: 2,
                minHeight: '30px'
              }),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              container: (provided, { isFocused }: any) => ({
                ...provided,
                width: '100%',
                border:
                  hasError && isTouched
                    ? '1px solid var(--colorRed)'
                    : !isFocused
                    ? '1px solid #E2E2E2'
                    : kind === 'primary'
                    ? '1px solid var(--colorBlue)'
                    : '1px solid var(--colorBlack)',
                boxShadow:
                  hasError && isTouched
                    ? '0 0 0 4px var(--colorRedFade)'
                    : !isFocused
                    ? ''
                    : kind === 'primary'
                    ? '0 0 0 4px var(--colorBlueFade)'
                    : '0 0 0 4px var(--colorGray2)',
                outline: isFocused && '0 none'
              }),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              option: (provided, { isSelected, isFocused, isDisabled }: any) => ({
                ...provided,
                backgroundColor: isSelected ? '' : isFocused ? 'var(--colorGray1)' : 'white',
                color: 'var(--colorGray8)',
                '&:hover': {
                  backgroundColor: 'var(--colorGray1)'
                },
                cursor: isDisabled ? 'not-allowed' : 'pointer'
              }),
              indicatorsContainer: () => ({
                cursor: 'pointer'
              }),
              clearIndicator: provided => ({
                ...provided,
                padding: '0 6px'
              }),
              valueContainer: provided => ({
                ...provided,
                padding: '0 12px'
              }),
              dropdownIndicator: () => ({
                display: 'none'
              }),
              indicatorSeparator: () => ({
                display: 'none'
              }),
              menuList: provided => ({
                ...provided,
                '::-webkit-scrollbar': {
                  width: '0px',
                  height: '0px'
                }
              })
            },
            placeholder: placeholder,
            isDisabled: disabled,
            value: value ? addressValue : googleAddress,
            onChange: async result => {
              if (result) {
                const parsedAddress = await parseGoogleAddress(result)
                const finalValue = {
                  label: generateLabelForGoogleAddress(parsedAddress, false),
                  value: parsedAddress
                }

                // Add addressId to the finalValue if it doesn't exist
                if (!finalValue.value.id) {
                  finalValue.value.id = addressId
                }
                setGoogleAddress(result)
                setAddressValue(finalValue)

                onChange?.(finalValue.value)
              } else {
                resetAddresses()
                if (placeOnly) {
                  onChange?.({
                    city: undefined,
                    state: undefined,
                    postcode: undefined,
                    country: undefined
                  })
                } else {
                  onChange?.({
                    street1: undefined,
                    street2: undefined,
                    city: undefined,
                    state: undefined,
                    postcode: undefined,
                    country: undefined
                  })
                }
              }
            }
          }}
          autocompletionRequest={{
            types: placeOnly ? ['(cities)'] : undefined,
            componentRestrictions: {
              country: restrictCountries ?? ''
            }
          }}
        />
      )}

      {hasManualMode && (
        <Button
          className={styles.manualBtn}
          kind="transparent"
          size="mini"
          disabled={disabled}
          isSubmitting={isSubmitting}
          onClick={handleChangeMode}>
          {isManual ? 'Search for an address' : 'Enter address manually'}
        </Button>
      )}
    </div>
  )
}

export default AddressAutocomplete
