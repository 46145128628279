import { FieldsetRow, FormField } from 'components/FormControls'
import { startCase } from 'lodash'

import { AddressLocationTypeEnum } from '../../../__generated__/globalTypes'

import styles from './AddressPickerFormFields.module.css'

export const locationTypeOptions: {
  label: string
  value: string
}[] = Object.entries(AddressLocationTypeEnum).map(([label, value]) => ({
  label: startCase(label.replace('_', ' ').toLowerCase()),
  value
}))

const AddressPickerFormFields = () => (
  <div className={styles.container}>
    <FieldsetRow>
      <FormField type="addressAutocomplete" name="addressAttributes" kind="primary" title="Address*" />
    </FieldsetRow>

    <FieldsetRow>
      <FormField
        className={styles.fieldWidth}
        type="select"
        name="locationType"
        kind="primary"
        title="Location Type"
        placeholder="Select a location type"
        options={locationTypeOptions}
      />
      <FormField className={styles.fieldWidth} type="text" name="nickname" kind="primary" title="Address Nickname" />
    </FieldsetRow>

    <FieldsetRow>
      <FormField className={styles.fieldWidth} type="text" name="contact" kind="primary" title="Contact Name*" />
      <FormField className={styles.fieldWidth} type="text" name="email" kind="primary" title="Contact Email*" />
    </FieldsetRow>

    <FieldsetRow>
      <FormField className={styles.fieldWidth} type="tel" name="phone" kind="primary" title="Telephone Number*" />
      <FormField className={styles.fieldWidth} type="tel" name="fax" kind="primary" title="Fax Number" />
    </FieldsetRow>
  </div>
)

export default AddressPickerFormFields
