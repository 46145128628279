import { useRef } from 'react'
import VerificationInput from 'react-verification-input'

import styles from './CodeInput.module.css'

type CodeInputProps = {
  value?: string
  onChange?: (value: string) => void
  onComplete?: (value: string) => void
  length?: number
  placeholder?: string

  autoFocus?: boolean
}

const CodeInput = ({ value, onChange, onComplete, length = 6, placeholder = '', autoFocus = true }: CodeInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <VerificationInput
      ref={inputRef}
      length={length}
      autoFocus={autoFocus}
      placeholder={placeholder}
      onComplete={() => {
        inputRef.current?.blur()
      }}
      inputProps={{
        className: styles.codeInputElement
      }}
      classNames={{
        container: styles.codeContainer,
        character: styles.codeChar,
        characterInactive: undefined,
        characterSelected: styles.codeCharSelected,
        characterFilled: styles.codeCharFilled
      }}
      value={value}
      onChange={event => {
        if (event.length === length) {
          onComplete?.(event)
        }

        onChange?.(event)
      }}
    />
  )
}

export default CodeInput
