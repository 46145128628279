import * as Yup from 'yup'
import { FormikHelpers } from 'formik'
import { useToast } from 'components/Toast'
import { useMutation } from 'hooks/useMutation'

import joinErrorPaths from 'lib/error-handling/join-error-paths'

import CREATE_ADDRESS_MUTATION from './graphql/CreateAddressMutation.graphql'
import UPDATE_ADDRESS_MUTATION from './graphql/UpdateAddressMutation.graphql'
import { CreateAddressMutation, CreateAddressMutationVariables } from './graphql/__generated__/CreateAddressMutation'
import { UpdateAddressMutation, UpdateAddressMutationVariables } from './graphql/__generated__/UpdateAddressMutation'
import {
  AddressableTypeEnum,
  AddressAttributesArguments,
  AddressLocationTypeEnum
} from '../../../__generated__/globalTypes'

export type UseAddressPickerFormArguments = {
  addressAttributes: AddressAttributesArguments
} & AddressAttributesArguments

export enum UseAddressPickerOperationEnum {
  ADD = 'Add',
  EDIT = 'Edit'
}

const validationSchema = Yup.object().shape({
  addressAttributes: Yup.object().shape({
    id: Yup.string().nullable(),
    street1: Yup.string().max(191).optional(),
    street2: Yup.string().max(191).optional().nullable(),
    city: Yup.string().max(191).required(),
    state: Yup.string().max(191).required(),
    country: Yup.string().max(191).required(),
    postcode: Yup.string().max(191).optional().nullable()
  }),
  locationType: Yup.mixed<AddressLocationTypeEnum>()
    .oneOf([...Object.values(AddressLocationTypeEnum), null])
    .optional()
    .nullable(),
  nickname: Yup.string().max(191).nullable(),
  contact: Yup.string().max(191).nullable().required(),
  email: Yup.string().email().max(191).nullable().required(),
  phone: Yup.string().max(191).nullable().required(),
  fax: Yup.string().max(191).nullable()
})

export type UseAddressPickerFormProps = {
  userId: string

  // For updating the address:
  addressId?: string

  // For creating a new address:
  addressableId?: string
  addressableType?: AddressableTypeEnum

  operation: UseAddressPickerOperationEnum
  data?: AddressAttributesArguments | null
  onCompleted?: (addressId?: string) => void
}

const useAddressPickerForm = ({
  userId,
  addressId,
  addressableId,
  addressableType,
  operation,
  data,
  onCompleted
}: UseAddressPickerFormProps) => {
  const [showToast] = useToast()

  const initialValues = {
    addressAttributes: {
      id: data?.id ?? '',
      street1: data?.street1 ?? '',
      street2: data?.street2 ?? null,
      city: data?.city ?? null,
      state: data?.state ?? null,
      postcode: data?.postcode ?? null,
      country: data?.country ?? null
    },
    locationType: data?.locationType,
    nickname: data?.nickname,
    contact: data?.contact,
    email: data?.email,
    phone: data?.phone,
    fax: data?.fax
  } as UseAddressPickerFormArguments

  const [createAddress] = useMutation<CreateAddressMutation, CreateAddressMutationVariables>(CREATE_ADDRESS_MUTATION, {
    context: { hasUpload: true },
    onCompleted: data => {
      if (data.createAddress.success) {
        const addressId = data.createAddress.address?.id
        onCompleted && onCompleted(addressId)
        showToast({ kind: 'success', message: 'Successfully created address' })
      }
    },
    onError: error => {
      console.error('Failed to update address', error)
    }
  })

  const [updateAddress] = useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UPDATE_ADDRESS_MUTATION, {
    context: { hasUpload: true },
    onCompleted: data => {
      if (data.updateAddress.success) {
        const addressId = data.updateAddress.address?.id
        onCompleted && onCompleted(addressId)
        showToast({ kind: 'success', message: 'Successfully updated address' })
      }
    },
    onError: error => {
      console.error('Failed to update address', error)
    }
  })

  const onSubmit = async (
    values: UseAddressPickerFormArguments,
    { setSubmitting, setFieldError }: FormikHelpers<UseAddressPickerFormArguments>
  ) => {
    setSubmitting(true)

    const attributes = validationSchema.cast(values) as unknown as UseAddressPickerFormArguments

    if (operation === UseAddressPickerOperationEnum.ADD && addressableType != null && addressableId != null) {
      const result = await createAddress({
        variables: {
          input: {
            addressableType: addressableType,
            addressableId: addressableId,
            attributes: {
              street1: attributes.addressAttributes.street1,
              street2: attributes.addressAttributes.street2,
              city: attributes.addressAttributes.city,
              state: attributes.addressAttributes.state,
              country: attributes.addressAttributes.country,
              postcode: attributes.addressAttributes.postcode,
              locationType: attributes.locationType,
              nickname: attributes.nickname,
              contact: attributes.contact,
              phone: attributes.phone,
              email: attributes.email,
              fax: attributes.fax
            }
          }
        },
        update: async (cache, result) => {
          if (result.data?.createAddress.success) {
            cache.evict({ id: `User:${userId}` })
            cache.gc()
          }
        }
      })
      const errors = result.data?.createAddress.errors
      errors?.forEach(e => (e.code && e.message ? setFieldError(e.code, e.message) : ''))
      errors?.forEach(e => (e.code && e.message ? showToast({ kind: 'error', message: e.message }) : ''))
    } else if (operation === UseAddressPickerOperationEnum.EDIT && addressId != null) {
      const result = await updateAddress({
        variables: {
          input: {
            id: addressId,
            attributes: {
              street1: attributes.addressAttributes.street1,
              street2: attributes.addressAttributes.street2,
              city: attributes.addressAttributes.city,
              state: attributes.addressAttributes.state,
              country: attributes.addressAttributes.country,
              postcode: attributes.addressAttributes.postcode,
              locationType: attributes.locationType,
              nickname: attributes.nickname,
              contact: attributes.contact,
              phone: attributes.phone,
              email: attributes.email,
              fax: attributes.fax
            }
          }
        },
        update: (cache, result) => {
          if (result.data?.updateAddress.success) {
            cache.evict({ id: `User:${userId}` })
            cache.gc()
          }
        }
      })

      const newErrors = joinErrorPaths(result.data?.updateAddress.errors)

      // Read GraphQL user errors, and apply to the form
      newErrors?.forEach(e => (e.path && e.message ? setFieldError(e.path, e.message) : ''))
      newErrors?.forEach(e => (!e.path && e.message ? showToast({ kind: 'error', message: e.message }) : ''))
    }

    setSubmitting(false)
  }

  return {
    initialValues,
    validationSchema,
    onSubmit
  }
}

export default useAddressPickerForm
