import { useFormikContext } from 'formik'

import Button from 'components/Button'
import ModalDialog from 'components/ModalDialog'
import { ModalContainerProps } from 'components/ModalContainer/ModalContainer'

import { AddressAttributesArguments } from '../../../__generated__/globalTypes'
import AddressPickerForm from 'components/AddressPickerForm'
import AddressPickerFormFields from 'components/AddressPickerForm/AddressPickerFormFields'
import { UseAddressPickerFormProps, UseAddressPickerOperationEnum } from '../AddressPickerForm/useAddressPickerForm'

type AddressPickerModalDialogProps = Pick<UseAddressPickerFormProps, 'operation'> & ModalContainerProps
type AddressPickerModalProps = Omit<UseAddressPickerFormProps, 'onCompleted'> & AddressPickerModalDialogProps

export const AddressPickerModalDialog = ({ operation, isOpen, onExit, ...rest }: AddressPickerModalDialogProps) => {
  const { isSubmitting, isValid, submitForm, dirty } = useFormikContext<AddressAttributesArguments>()

  const handleClose = () => {
    onExit()
  }

  return (
    <ModalDialog
      title={`${operation} Address`}
      onExit={handleClose}
      isOpen={isOpen}
      closeable
      buttonsRight={
        <>
          <Button kind="default" disabled={isSubmitting} onClick={handleClose}>
            Cancel
          </Button>

          <Button
            kind="primary"
            disabled={!isValid || isSubmitting || !dirty}
            isSubmitting={isSubmitting}
            onClick={submitForm}>
            {`${operation === UseAddressPickerOperationEnum.ADD ? operation : 'Update'} Address`}
          </Button>
        </>
      }
      {...rest}>
      <AddressPickerFormFields />
    </ModalDialog>
  )
}

const AddressPickerModal = ({
  userId,
  addressId,
  addressableId,
  addressableType,
  operation,
  data,
  isOpen,
  onExit,
  ...rest
}: AddressPickerModalProps) => {
  return (
    <AddressPickerForm
      userId={userId}
      addressId={addressId}
      addressableId={addressableId}
      addressableType={addressableType}
      operation={operation}
      data={data}
      onCompleted={onExit}>
      <AddressPickerModalDialog operation={operation} isOpen={isOpen} onExit={onExit} {...rest} />
    </AddressPickerForm>
  )
}

export default AddressPickerModal
